/* Grid layout styles and fallback */

/* olde worlde layout */
.content-wrapper {
  padding: 16px 16px 32px;
  width: 960px;
  margin: 0 auto;
}

header {
  padding-bottom: 1rem;
}

.art-wrapper {
  float: left;
  width: 36%;
}

.main-content {
  padding-bottom: 1rem;
}

.contents-menu {
  padding-bottom: 1rem;
}

footer {
  padding-top: 1rem;
}

/* grid layout */
@supports (display: grid) {
  .content-wrapper {
    display: grid;
    -ms-grid-columns: auto 46rem auto;
    grid-template-columns: 1fr minmax(87px, 16rem) minmax(173px, 30rem) 1fr;
    -ms-grid-rows: 4;
    grid-template-rows: 4;
    width: auto;
  }

  header {
    grid-column: 2 / span 2;
    grid-row: 1;
    z-index: 2;
  }

  .main-content {
    grid-column: 2 / span 2;
    grid-row: 2;
    z-index: 2;
  }

  .contents-menu {
    grid-column: 2 / span 2;
    grid-row: 3;
    z-index: 2;
  }

  .art-wrapper {
    grid-column: 2 / span 2;
    grid-row: 2;
    z-index: 1;
    width: auto;
    float: none;
  }

  .art-wrapper .yarbus-art {
    width: auto;
    float: none;
  }

  footer {
    grid-column: 2 / span 2;
    grid-row: 4;
    z-index: 2;
  }

  @media only screen and (min-width: 40em) {
    .art-wrapper {
      grid-column: 2;
    }

    .is-home .content-wrapper .main-content {
      padding-top: 0;
      grid-column: 3;
    }
  }

  @media only screen and (min-width: 768px) {
    .art-wrapper {
      grid-column: 2;
    }

    .is-home .content-wrapper .main-content {
      padding-top: 0;
      grid-column: 3;
    }
  }
}
